<template>
  <b-overlay
    :show="isLoading"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>

        <b-form @submit.prevent="submit">
          <div class="mb-2 d-flex justify-content-between">
            <div>
              <p class="text-danger">
                (ปิด - เปิด) เปิดไพ่
              </p>
              <div>
                <b-form-checkbox
                  v-model="setting_data.status"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div class="d-flex">
              <b-form-group
                label="เทิร์น:"
                class="mr-1"
              >
                <b-form-input
                  v-model="setting_data.turn"
                  type="text"
                />
              </b-form-group>
              <b-button
                variant="success"
                type="submit"
                style="height: 38px;margin-top: 29px;"
              >
                บันทึกข้อมูล
              </b-button>
            </div>
          </div>
          <div
            v-for="(items, idx) in setting_data.reward"
            :key="idx"
            class="row"
          >
            <!-- {{ items }} -->
            <div class="col-8">
              <b-form-group :label="`ไพ่ที่ ${idx + 1} ประเภท:`">
                <b-form-select
                  v-model="items.reward_type"
                  :options="bonusTypes"
                  type="text"
                  required
                />
              </b-form-group>
            </div>
            <!-- <div class="col-4">
              <b-form-group label="มูลค่า:">
                <b-form-input
                  v-model="items.bonus"
                  :disabled="items.reward_type === 'no_reward'"
                  type="number"
                />
              </b-form-group>
            </div> -->
            <div class="col-4">
              <b-form-group label="เปอร์เซนต์ได้:">
                <b-form-input
                  v-model="items.percent"
                  type="number"
                />
              </b-form-group>
            </div>
          </div>
        </b-form>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BForm, BButton, BModal, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BCardBody, VBToggle, BOverlay, BIconController, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm, BButton, BModal, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BCardBody, BOverlay, BIconController, BFormCheckbox,
  },
  data: () => ({
    setting_data: null,
    isLoading: false,
    bonusTypes: [
      { text: 'ไม่ได้รับรางวัล', value: 'no_reward' },
      { text: 'ได้รับ 1 เพชร', value: 'chip' },
      { text: 'ได้รับเครดิต 100 บาท', value: 'credit' },
    ],
  }),
  mounted() {
    this.getSetting()
  },
  methods: {
    async submit(e) {
      const totalPercent = this.setting_data.reward.reduce((sum, item) => sum + Number(item.percent), 0)
      console.log(totalPercent)
      if (totalPercent !== 100) {
        await this.$swal({
          icon: 'warning',
          title: '<h3 style="color: #141414">ทำรายการไม่สำเร็จ</h3>',
          text: 'เปอร์เซนต์รวมต้องเท่ากับ100',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        this.isLoading = true
        try {
          await this.$http.post('/events/card/setting', this.setting_data)

          await this.getSetting()
          await this.$swal({
            icon: 'success',
            title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
            text: 'บันทึกข้อมูลเรียบร้อย',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } catch (error) {
          console.log(error)
        } finally {
          this.isLoading = false
        }
      }
    },
    async getSetting() {
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/events/card/setting')

        this.setting_data = data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
